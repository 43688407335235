import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../definitions";

const initialState: UserState = {
  id: "",
  name: "",
  preferred_username: "",
  emails: [],
  roles: [],
  auth: {
    accessToken: "",
    account: null,
    idToken: "",
    expiresOn: new Date(),
  },
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        preferred_username: action.payload.preferred_username,
        emails: action.payload.emails,
        roles: action.payload.roles,
        auth: {
          accessToken: action.payload.auth.accessToken,
          account: action.payload.auth.account,
          expiresOn: action.payload.auth.expiresOn,
          idToken: action.payload.auth.idToken,
        },
      };
    },
    deleteAuthData: (state) => {
      return initialState;
    },
  },
});

export default authSlice.reducer;
export const { setAuthData, deleteAuthData } = authSlice.actions;
